import * as React from 'react';
import './App.css';
import ResponsiveAppBar from "./AppBar";
import { HttpTotal } from './http-total';
import { DomainsPopular } from './domains-popular';
import { AttackLayerTraffic } from './AttackLayerTraffic';
import { Box } from '@mui/system';

const App = () => {
  
  const [task, setTask] = React.useState("")

  const handlehttptotal = () => {
    setTask("http-total");
  }

  const handlePopularDomains = () => {
    setTask("PopularDonains");
  }

  const handleAttackLayer = () => {
    setTask("AttackLayer");
  }

  const pages = [
    { text: 'HTTP vs Total', handler: handlehttptotal },
    { text: 'Domains', handler: handlePopularDomains},
    { text: 'Layer3 Attacks', handler: handleAttackLayer}
  ]


  const getComponentToRender = () => {
    return  task === "http-total" ? <HttpTotal /> :
            task === "PopularDonains" ? <DomainsPopular /> :
            task === "AttackLayer" ? <AttackLayerTraffic /> : <Box></Box>
  }

  return (
    <Box className="App" backgroundColor= 'orange'>
      <ResponsiveAppBar pages={pages} />
      {task ? getComponentToRender() : <></>}
    </Box>
  );
}

export default App;
